<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Actualizar empresa</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />
          <h4 class="mb-2">DATOS DE LA EMPRESA: (en caso de sociedades)</h4>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="legal_name"
                :rules="legal_name_Rules"
                :counter="190"
                prepend-icon="mdi-store"
                label="Razón Social *"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="rut"
                :rules="rutRules"
                :counter="12"
                label="RUT *"
                prepend-icon="mdi-card-text-outline"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="trade_name"
                :rules="trade_name_Rules"
                :counter="100"
                prepend-icon="mdi-store-outline"
                label="Nombre de Fantasía *"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="address"
                :rules="address_Rules"
                :counter="100"
                prepend-icon="mdi-map-marker-outline"
                label="Dirección *"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="acteco" :rules="actecoRules" :counter="100" prepend-icon="mdi-store-outline"
                label="Actividad económica *" required></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="giro" :counter="250" :rules="giroRules" prepend-icon="mdi-ballot-recount"
                label="Giro" required>
              </v-text-field>
  </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="12"
                prepend-icon="mdi-deskphone"
                label="Teléfono 1 *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="phone2"
                :rules="phoneRules"
                :counter="12"
                prepend-icon="mdi-deskphone"
                label="Teléfono 2"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="phone3"
                :rules="phoneRules"
                :counter="12"
                prepend-icon="mdi-deskphone"
                label="Teléfono 3"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="mail"
                :rules="emailRules"
                prepend-icon="mdi-at"
                label="Correo electrónico *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sii_key"
                :rules="sii_keyRules"
                prepend-icon="mdi-key"
                label="Clave del S.I.I"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br />
          <!-- Q -->
          <h4 class="mb-2">
            DATOS DEL CLIENTE O DATOS DEL REPRESENTANTE LEGAL: (en caso de
            sociedades)
          </h4>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="legal_representative"
                :rules="name_Rules"
                :counter="190"
                prepend-icon="mdi-account"
                label="Nombre del representante legal*"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="rut_legal_representative"
                :rules="rut_legal_representativeRules"
                :counter="12"
                label="RUT del representante legal*"
                prepend-icon="mdi-card-account-details"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="address_legal_representative"
                :rules="address_Rules"
                :counter="100"
                prepend-icon="mdi-map-marker-outline"
                label="Dirección del representante legal"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="comune"
                :rules="comuneRules"
                :counter="100"
                prepend-icon="mdi-map-marker-outline"
                label="Comuna del representante legal"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="phone_legal_representative"
                :rules="phoneRules"
                :counter="12"
                prepend-icon="mdi-deskphone"
                label="Teléfono 1 del representante legal"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="phone2_legal_representative"
                :rules="phoneRules"
                :counter="12"
                prepend-icon="mdi-deskphone"
                label="Teléfono 2 del representante legal"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="phone3_legal_representative"
                :rules="phoneRules"
                :counter="12"
                prepend-icon="mdi-deskphone"
                label="Teléfono 3 del representante legal"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="email_legal_representative"
                :rules="emailRules"
                prepend-icon="mdi-at"
                label="Correo electrónico del representante legal"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="accountand"
                :rules="name_Rules"
                :counter="190"
                prepend-icon="mdi-account"
                label="Nombre del contador"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="phone_accountand"
                :rules="phoneRules"
                :counter="12"
                prepend-icon="mdi-deskphone"
                label="Teléfono del contador"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="email_accountand"
                :rules="emailRules"
                prepend-icon="mdi-at"
                label="Correo electrónico del contador"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="8"></v-col>
            <v-col cols="12" lg="4">
              <v-btn color="primary" outlined class="mr-4" @click="cancel">
                <v-icon>mdi-close-circle</v-icon> <span> Cancelar</span>
              </v-btn>
              <v-btn color="primary" outlined @click="update">
                <v-icon>mdi-sync-circle</v-icon> <span>Actualizar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    //Modelolegal_name: "",
    trade_name: "",
    rut: "",
    record_number: "",
    acteco: "",
    giro:"",
    address: "",
    mail: "",
    phone: "",
    phone2: "",
    phone3: "",
    sii_key: "",
    legal_representative: "",
    rut_legal_representative: "",
    address_legal_representative: "",
    comune: "",
    phone_legal_representative: "",
    phone2_legal_representative: "",
    phone3_legal_representative: "",
    email_legal_representative: "",
    accountand: "",
    phone_accountand: "",
    email_accountand: "",
    firm_key:"",
  }),

  mounted() {
  
    this.chargeData();
  },
  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "customers" }), 10);
    },
    getService(id_service) {
      let service = this.services.find(
        (service) => (service.id_service = id_service)
      );
      return service.id_service;
    },
    chargeData2() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };

      axios
        .post("/customer", request)
        .then((response) => {
          console.log(response.data);
          this.legal_name = response.data.legal_name;
          this.trade_name = response.data.trade_name;
          this.rut = response.data.rut;
          this.record_number = response.data.record_number;
          this.address = response.data.address;
          this.mail = response.data.mail;
          this.phone = response.data.phone;
          this.phone2 = response.data.phone2;
          this.phone3 = response.data.phone3;
          this.sii_key = response.data.sii_key;
          this.legal_representative = response.data.legal_representative;
          this.rut_legal_representative =
            response.data.rut_legal_representative;
          this.address_legal_representative =
            response.data.address_legal_representative;
          this.comune = response.data.comune;
          this.phone_legal_representative =
            response.data.phone_legal_representative;
          this.phone2_legal_representative =
            response.data.phone2_legal_representative;
          this.phone3_legal_representative =
            response.data.phone3_legal_representative;
          this.email_legal_representative =
            response.data.email_legal_representative;
          this.accountand = response.data.accountand;
          this.phone_accountand = response.data.phone_accountand;
          this.email_accountand = response.data.email_accountand;
          this.firm_key = response.data.firm_key;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al cargar datos de cliente"
          );
        });
    },

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/customer", request)
        .then((response) => {
          console.log(response.data);
          this.legal_name = response.data.legal_name;
          this.trade_name = response.data.trade_name;
          this.rut = response.data.rut;
          this.record_number = response.data.record_number;
          this.acteco = response.data.acteco;
          this.giro = response.data.giro;
          this.address = response.data.address;
          this.mail = response.data.mail;
          this.phone = response.data.phone;
          this.phone2 = response.data.phone2;
          this.phone3 = response.data.phone3;
          this.sii_key = response.data.sii_key;
          this.legal_representative = response.data.legal_representative;
          this.rut_legal_representative =
            response.data.rut_legal_representative;
          this.address_legal_representative =
            response.data.address_legal_representative;
          this.comune = response.data.comune;
          this.phone_legal_representative =
            response.data.phone_legal_representative;
          this.phone2_legal_representative =
            response.data.phone2_legal_representative;
          this.phone3_legal_representative =
            response.data.phone3_legal_representative;
          this.email_legal_representative =
            response.data.email_legal_representative;
          this.accountand = response.data.accountand;
          this.phone_accountand = response.data.phone_accountand;
          this.email_accountand = response.data.email_accountand;
          this.firm_key = response.data.firm_key;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al cargar datos de cliente");
});
},


update() {
      console.log("actualizando plan");
      let request = {
        id: this.$route.params.id,
        legal_name: this.legal_name,
        trade_name: this.trade_name,
        rut: this.rut,
        record_number: this.record_number,
        acteco: this.acteco,
        giro: this.giro,
        address: this.address,
        mail: this.mail,
        phone: this.phone,
        phone2: this.phone2,
        phone3: this.phone3,
        sii_key: this.sii_key,
        legal_representative: this.legal_representative,
        rut_legal_representative: this.rut_legal_representative,
        address_legal_representative: this.address_legal_representative,
        comune: this.comune,
        phone_legal_representative: this.phone_legal_representative,
        phone2_legal_representative: this.phone2_legal_representative,
        phone3_legal_representative: this.phone3_legal_representative,
        email_legal_representative: this.email_legal_representative,
        accountand: this.accountand,
        phone_accountand: this.phone_accountand,
        email_accountand: this.email_accountand,
        firm_key: this.firm_key,
      };

      axios
        .post("/update-customer", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "customers" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al actualizar el cliente");
          setTimeout(() => this.$router.push({ name: "customers" }), 4000);
  });},
    update2() {
      console.log("actualizando plan");
      let request = {
        id: this.$route.params.id,
        legal_name: this.legal_name,
        trade_name: this.trade_name,
        rut: this.rut,
        record_number: this.record_number,
        address: this.address,
        mail: this.mail,
        phone: this.phone,
        phone2: this.phone2,
        phone3: this.phone3,
        sii_key: this.sii_key,
        legal_representative: this.legal_representative,
        rut_legal_representative: this.rut_legal_representative,
        address_legal_representative: this.address_legal_representative,
        comune: this.comune,
        phone_legal_representative: this.phone_legal_representative,
        phone2_legal_representative: this.phone2_legal_representative,
        phone3_legal_representative: this.phone3_legal_representative,
        email_legal_representative: this.email_legal_representative,
        accountand: this.accountand,
        phone_accountand: this.phone_accountand,
        email_accountand: this.email_accountand,
        firm_key: this.firm_key,
      };
      console.log(request);

      axios
        .post("/update-customer", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "customers" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al actualizar el cliente"
          );
          setTimeout(() => this.$router.push({ name: "customers" }), 4000);
        });
    },
  },
};
</script>